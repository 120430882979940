<template>
  <div class="background full-height full-width">
    <!-- Loader -->
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <!-- Dialogs for Success and Error messages -->
    <v-dialog v-model="dialogSuccess" width="500px" height="500px">
      <v-sheet elevation="12" max-width="600" rounded="lg" width="100%" class="pa-4 text-center mx-auto">
        <v-icon class="animated-icon" color="green" size="112">
          mdi-check-circle
        </v-icon>
        <h2 class="text-h5 mb-6 my-3">Check your inbox</h2>
        <p class="mb-4 text-medium-emphasis text-body-2">
          We sent you an email with instructions to reset your password.
        </p>
        <v-divider class="mb-4"></v-divider>
        <div class="text-center">
          <v-btn class="text-none my-2" color="black" rounded outlined dark variant="flat" width="200px"
            @click="goToPage('/')">
            Close
          </v-btn>
        </div>
      </v-sheet>
    </v-dialog>

    <!-- Form for password reset -->
    <div class="gradient">
      <v-row justify="center">
        <a href="https://dashboard.hodlotc.com/" target="_blank">
          <v-img src="../assets/images/Logo.png" contain height="80" class="mt-16"></v-img>
        </a>
      </v-row>
      <v-card class="mx-auto pa-12 pb-1 text-center card" elevation="8" max-width="448" style="
          margin-top: 100px;
          background: rgb(0, 0, 0);
          background: linear-gradient(8deg, rgba(0, 0, 0, 1) 0%, rgba(7, 24, 92, 1) 100%);
        " rounded="lg" color="#373737" dark>
        <v-row justify="center">
          <div>
            <h2 style="color: white;">Forgot Password?</h2>
          </div>
        </v-row>
        <v-row>
          <v-text-field solo outlined light dense rounded label="Email" ref="email" v-model="formattedEmail"
            @input="sanitizeEmailInput" density="compact" placeholder="Enter your email"
            prepend-inner-icon="mdi-email-outline" variant="outlined" style="margin-top: 20px;"
            class="field"></v-text-field>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" sm="6" md="7">
            <v-btn solo rounded block class="mb-8" light color="white" width="50%" variant="tonal"
              @click="validateAndResetPassword">
              Send
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
import { db } from "../firebaseConfig.js";
import emailjs from "emailjs-com";

export default {
  data() {
    return {
      dialogSuccess: false,
      dialogError: false,
      loading: false, // Loader state
      rawEmail: "",  // The email input captured here
      emailRules: [
        (v) => !!v || "Please enter your email.",
        (v) => /.+@.+\..+/.test(v) || "Please enter a valid email address.",
      ],
      errorMessage: "",
    };
  },
  methods: {
    goToPage(path) {
      this.$router.push({ path });
    },
    sanitizeEmailInput() {
      this.rawEmail = this.rawEmail.replace(/[\s\t]/g, "").toLowerCase();
    },
    async validateAndResetPassword() {
      let emailValid = this.$refs.email.validate();

      if (!emailValid) {
        this.showError("Please provide a valid email address.");
        return;
      }

      this.loading = true; // Start loader

      try {
        // Call Cloud Function to generate reset link
        const resetLink = await this.getPasswordResetLink(this.rawEmail); // Use rawEmail for the email

        // Use EmailJS to send the reset link
        await this.sendEmailWithResetLink(this.rawEmail, resetLink); // Pass rawEmail to the function

        // Show success notification
        this.showSuccess("Check your inbox. We've sent instructions to reset your password.");
        this.displaySuccessMessage();
      } catch (error) {
        if (error.message.includes("network")) {
          this.showError("Network issue. Please try again later.");
        } else if (error.message.includes("email address does not exist")) {
          this.showError("We couldn't find an account with that email address.");
        } else {
          this.showError("Sorry, we couldn't process your request. Please try again.");
        }
      } finally {
        this.loading = false; // Stop loader
      }
    },
    async getPasswordResetLink(email) {
      try {
        const response = await fetch("https://europe-west2-hodl-f3ae3.cloudfunctions.net/sendCustomPasswordResetEmail", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        });

        const data = await response.json();

        if (response.ok) {
          return data.resetLink;
        } else if (response.status === 404) {
          throw new Error("This email address does not exist.");
        } else {
          throw new Error(data.error || "Failed to generate password reset link.");
        }
      } catch (error) {
        console.error("Error getting password reset link:", error);
        throw new Error("network error");
      }
    },
    async sendEmailWithResetLink(email, resetLink) {
      try {
        const templateParams = {
          to_email: email,  // The recipient's email address
          message: "You requested to reset your password. Click the link below to proceed.",
          Password_Reset: resetLink,  // The reset password link
        };

        const response = await emailjs.send(
          "service_9esjl09",  // Your EmailJS service ID
          "template_tlwbzx8", // Your EmailJS template ID
          templateParams,
          "mr1E1BaGMKZMEzWVu" // Your EmailJS user ID
        );

        console.log("Password reset email sent via EmailJS", response.status, response.text);
      } catch (error) {
        throw new Error("email sending failed");
      }
    },
    showSuccess(message) {
      this.$toast.success(message, { position: "top-right", timeout: 5000 });
    },
    showError(message) {
      this.$toast.error(message, { position: "top-right", timeout: 5000 });
    },
    displaySuccessMessage() {
      this.dialogSuccess = true;
    },
    closeErrorDialog() {
      this.dialogError = false;
    },
  },
  computed: {
    formattedEmail: {
      get() {
        return this.rawEmail;
      },
      set(value) {
        this.rawEmail = value.toLowerCase(); // Convert to lowercase
      },
    },
  },
};
</script>

<style scoped>
.background {
  background-image: url("../assets/images/Login.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.card {
  margin: 0 auto;
  margin-top: 200px;
  max-width: 448px;
  background: rgb(0, 0, 0);
  background: linear-gradient(8deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(7, 24, 92, 1) 100%);
  width: 100%;
}
</style>
