<template>
  <v-container fill-height class="pa-0 full-width register">
    <video autoplay muted loop class="video" disablePictureInPicture>
      <source
        src="https://firebasestorage.googleapis.com/v0/b/web-hodl.appspot.com/o/Globe.mp4?alt=media&token=ea7a6d48-ab84-40b8-b5ba-9f9187614376"
        type="video/mp4" />
    </video>
    <v-dialog v-model="dialog" width="500px" height="500px">
      <v-sheet
        elevation="12"
        max-width="600"
        rounded="lg"
        width="100%"
        class="pa-4 text-center mx-auto">
        <v-icon class="animated-icon" color="green" size="112">
          mdi-check-circle
        </v-icon>
        <h2 class="text-h5 mb-6 my-3">REGISTRATION CONFIRMATION</h2>

        <p class="mb-4 text-medium-emphasis text-body-2">
          Are you sure you would like to proceed with registration
          <br />
        </p>
        <v-divider class="mb-4"></v-divider>
        <div class="text-center">
          <v-btn
            class="text-none my-2"
            color="#07185c"
            rounded
            outlined
            dark
            variant="flat"
            width="200px"
            @click="
              createUserAuth();
              sendReferralInfo();
            ">
            Yes
          </v-btn>
          <v-btn
            class="text-none mx-2 my-2"
            color="#07185c"
            rounded
            outlined
            dark
            variant="outlined"
            @click="dialog = false"
            width="200px">
            No
          </v-btn>
        </div>
      </v-sheet>
    </v-dialog>
    <div
      class="register background full-width"
      :style="{ 'min-height': '100vh' }">
      <v-row justify="center">
        <v-alert type="success" v-model="showSuccessAlert" dismissible>
          Registration successful!
        </v-alert>
        <v-alert type="error" v-model="showErrorAlert" dismissible>
          {{ errorMessage }}
        </v-alert>
      </v-row>
      <transition name="slide">
        <div v-if="showForm && !uploadingDocs">
          <v-row justify="center" class="mt-5">
            <a href="https://hodlotc.com/" target="_blank">
              <v-img
                src="../assets/images/Logo.png"
                contain
                height="80"></v-img>
            </a>
          </v-row>
          <v-row justify="center">
            <v-card
              class="card transparent-card pt-6 mb-12"
              :style="{ padding: '3rem' }"
              width="800px">
              <h1 class="white-text text-center mt-2">
                REGISTRATION FORM AS INDIVIDUAL OR COMPANY
              </h1>
              <v-row justify="center">
                <div class="mt-2" :style="{ 'max-width': '500px' }">
                  <v-row justify="center">
                    <v-radio-group
                      :hide-details="true"
                      v-model="regType"
                      dark
                      class="reg-type"
                      row>
                      <v-radio
                        value="Individual"
                        color="pink"
                        off-icon="mdi-square-outline"
                        on-icon="mdi-checkbox-marked"
                        label="Individual"></v-radio>
                      <v-radio
                        value="Company"
                        color="pink"
                        off-icon="mdi-square-outline"
                        on-icon="mdi-checkbox-marked"
                        label="Company"></v-radio>
                      <v-radio
                        value="Trust"
                        color="pink"
                        off-icon="mdi-square-outline"
                        on-icon="mdi-checkbox-marked"
                        label="Trust"></v-radio>
                    </v-radio-group>
                  </v-row>

                  <v-col class="px-1 py-0" v-if="regType === 'Trust'">
                    <v-text-field
                      v-model="TrustName"
                      prepend-icon="mdi-form-select"
                      placeholder="Trust Name:"
                      dark
                      rounded
                      class="mt-4 px-4 pt-0 textfield auto full-width"
                      :append-outer-icon="
                        hasStartedTyping.TrustName &&
                        getErrorMessages('TrustName').length
                          ? 'mdi-alert-circle'
                          : ''
                      ">
                      <template v-slot:append-outer>
                        <v-tooltip
                          v-if="
                            hasStartedTyping.TrustName &&
                            getErrorMessages('TrustName').length
                          "
                          right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" color="red">
                              mdi-alert-circle
                            </v-icon>
                          </template>
                          <span class="custom-tooltip">{{
                            getErrorMessages("TrustName").join(", ")
                          }}</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    class="px-1 py-0"
                    v-if="regType === 'Company'">
                    <v-text-field
                      v-model="companyName"
                      prepend-icon="mdi-office-building"
                      placeholder="Company Name:"
                      dark
                      rounded
                      bold
                      class="mt-4 px-4 pt-0 textfield auto full-width"
                      :append-outer-icon="
                        hasStartedTyping.companyName &&
                        getErrorMessages('companyName').length
                          ? 'mdi-alert-circle'
                          : ''
                      ">
                      <template v-slot:append-outer>
                        <v-tooltip
                          v-if="
                            hasStartedTyping.companyName &&
                            getErrorMessages('companyName').length
                          "
                          right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" color="red">
                              mdi-alert-circle
                            </v-icon>
                          </template>
                          <span class="custom-tooltip">{{
                            getErrorMessages("companyName").join(", ")
                          }}</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" class="px-1 py-0">
                    <v-text-field
                      v-model="name"
                      prepend-icon="mdi-account"
                      placeholder="Name:"
                      dark
                      rounded
                      class="mt-4 px-4 pt-0 textfield auto full-width"
                      :append-outer-icon="
                        hasStartedTyping.name && getErrorMessages('name').length
                          ? 'mdi-alert-circle'
                          : ''
                      ">
                      <template v-slot:append-outer>
                        <v-tooltip
                          v-if="
                            hasStartedTyping.name &&
                            getErrorMessages('name').length
                          "
                          right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" color="red">
                              mdi-alert-circle
                            </v-icon>
                          </template>
                          <span class="custom-tooltip">{{
                            getErrorMessages("name").join(", ")
                          }}</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" class="px-1 py-0">
                    <v-text-field
                      v-model="lastName"
                      prepend-icon="mdi-account"
                      placeholder="Last Name:"
                      dark
                      rounded
                      class="mt-4 px-4 pt-0 textfield auto full-width"
                      :append-outer-icon="
                        hasStartedTyping.lastName &&
                        getErrorMessages('lastName').length
                          ? 'mdi-alert-circle'
                          : ''
                      ">
                      <template v-slot:append-outer>
                        <v-tooltip
                          v-if="
                            hasStartedTyping.lastName &&
                            getErrorMessages('lastName').length
                          "
                          right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" color="red">
                              mdi-alert-circle
                            </v-icon>
                          </template>
                          <span class="custom-tooltip">{{
                            getErrorMessages("lastName").join(", ")
                          }}</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" class="px-1 py-0">
                    <v-text-field
                      v-model="formattedEmail"
                      prepend-icon="mdi-mail"
                      placeholder="Email Address:"
                      dark
                      @input="sanitizeEmailInput"
                      rounded
                      class="mt-4 px-4 pt-0 textfield auto full-width"
                      :append-outer-icon="
                        hasStartedTyping.formattedEmail &&
                        getErrorMessages('formattedEmail').length
                          ? 'mdi-alert-circle'
                          : ''
                      ">
                      <template v-slot:append-outer>
                        <v-tooltip
                          v-if="
                            hasStartedTyping.formattedEmail &&
                            getErrorMessages('formattedEmail').length
                          "
                          right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" color="red">
                              mdi-alert-circle
                            </v-icon>
                          </template>
                          <span class="custom-tooltip">{{
                            getErrorMessages("formattedEmail").join(", ")
                          }}</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" class="px-1 py-0">
                    <v-text-field
                      v-model="password"
                      :type="showPassword ? 'text' : 'password'"
                      prepend-icon="mdi-lock"
                      :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                      @click:append="showPassword = !showPassword"
                      placeholder="Password:"
                      dark
                      rounded
                      class="mt-4 px-4 pt-0 textfield auto full-width"
                      :append-outer-icon="
                        hasStartedTyping.password &&
                        getErrorMessages('password').length
                          ? 'mdi-alert-circle'
                          : ''
                      ">
                      <template v-slot:append-outer>
                        <v-tooltip
                          v-if="
                            hasStartedTyping.password &&
                            getErrorMessages('password').length
                          "
                          right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" color="red">
                              mdi-alert-circle
                            </v-icon>
                          </template>
                          <span class="custom-tooltip">{{
                            getErrorMessages("password").join(", ")
                          }}</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" class="px-1 py-0">
                    <v-text-field
                      v-model="confirmPassword"
                      :type="showConfirmPassword ? 'text' : 'password'"
                      prepend-icon="mdi-lock"
                      :append-icon="
                        showConfirmPassword ? 'mdi-eye-off' : 'mdi-eye'
                      "
                      @click:append="showConfirmPassword = !showConfirmPassword"
                      placeholder="Confirm Password:"
                      dark
                      rounded
                      class="mt-4 px-4 pt-0 textfield auto full-width"
                      :append-outer-icon="
                        hasStartedTyping.confirmPassword &&
                        getErrorMessages('confirmPassword').length
                          ? 'mdi-alert-circle'
                          : ''
                      ">
                      <template v-slot:append-outer>
                        <v-tooltip
                          v-if="
                            hasStartedTyping.confirmPassword &&
                            getErrorMessages('confirmPassword').length
                          "
                          right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" color="red">
                              mdi-alert-circle
                            </v-icon>
                          </template>
                          <span class="custom-tooltip">
                            {{
                              getErrorMessages("confirmPassword").join(", ")
                            }}</span
                          >
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" class="px-1 py-0">
                    <v-select
                      v-model="selectedCountry"
                      prepend-icon="mdi-web"
                      placeholder="Country:"
                      dark
                      rounded
                      no-data-text="No countries available"
                      class="mt-4 px-4 pt-0 textfield auto full-width"
                      :items="countries"
                      @change="updatePhoneNumber">
                      <template v-slot:item="{ item }">
                        <img :src="item.imageUrl" class="flag-icon" />
                        {{ item.text }}
                      </template>
                    </v-select>
                  </v-col>
                  <v-row>
                    <v-col cols="12" class="px-1 py-0 mt-n3">
                      <v-row align="center">
                        <v-col cols="4" class="pr-0">
                          <v-select
                            v-model="selectedCountryCode"
                            :items="CellCode"
                            item-text="text"
                            item-value="value"
                            placeholder="Country Code"
                            :rules="[(v) => !!v || 'Country code is required']"
                            dark
                            rounded
                            class="mt-4 pt-0 textfield auto full-width right-align"
                            @change="updatePhoneNumber">
                            <template v-slot:item="{ item }">
                              <img :src="item.imageUrl" class="flag-icon" />
                              {{ item.text }}
                            </template>
                            <template v-slot:selection="{ item }">
                              <img
                                v-if="item && item.imageUrl"
                                :src="item.imageUrl"
                                class="flag-icon" />
                              <span v-if="item">{{ item.text }}</span>
                            </template>
                          </v-select>
                        </v-col>
                        <!-- Phone Number Input -->
                        <v-col cols="8" class="pl-0">
                          <v-text-field
                            v-model="PhoneNumber"
                            @input="handleInput"
                            @focus="hasStartedTyping.PhoneNumber = true"
                            prepend-icon="mdi-phone"
                            placeholder="Phone Number"
                            dark
                            rounded
                            class="mt-4 px-4 pt-0 textfield auto full-width"
                            :append-outer-icon="
                              hasStartedTyping.PhoneNumber &&
                              getErrorMessages('PhoneNumber').length
                                ? 'mdi-alert-circle'
                                : ''
                            ">
                            <template v-slot:append-outer>
                              <v-tooltip
                                v-if="
                                  hasStartedTyping.PhoneNumber &&
                                  getErrorMessages('PhoneNumber').length
                                "
                                right>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on" color="red">
                                    mdi-alert-circle
                                  </v-icon>
                                </template>
                                <span class="custom-tooltip">{{
                                  getErrorMessages("PhoneNumber").join(", ")
                                }}</span>
                              </v-tooltip>
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row justify="center" class="mt-4">
                    <v-col cols="2" md="1" class="px-0 py-0">
                      <v-checkbox
                        color="pink"
                        dark
                        v-model="isAgreed"
                        :rules="[
                          (v) =>
                            !!v || 'You must agree to the terms and conditions',
                        ]"></v-checkbox>
                    </v-col>
                    <v-col cols="8" md="11" class="px-0 py-0">
                      <p class="white-text smallParagraph">
                        I hereby confirm that I have read and agree to the
                        <a
                          href="https://hodlotc.com/TCs.pdf"
                          target="_blank"
                          class="white-text"
                          >Terms & Conditions</a
                        >
                        and
                        <a
                          href="https://hodlotc.com/PrivacyPolicy.pdf"
                          target="_blank"
                          class="white-text"
                          >Privacy Policy</a
                        >
                        of HODL OTC.
                      </p>
                    </v-col>
                  </v-row>
                  <v-row justify="center" class="full-width">
                    <v-btn
                      @click="$router.push('/')"
                      dark
                      rounded
                      class="white-btn my-5 mx-1"
                      >Back</v-btn
                    >
                    <v-btn
                      v-if="isFormValid"
                      @click="
                        createUserAuth();
                        sendReferralInfo();
                        sendAdminNotification();
                        executeRecaptcha();
                      "
                      dark
                      rounded
                      class="pink-btn my-5 mx-1"
                      >Register</v-btn
                    >
                  </v-row>
                </div>
              </v-row>
            </v-card>
          </v-row>
        </div>
      </transition>
    </div>
    <!-- Email Verification Dialog -->
    <v-dialog v-model="verifyDialog" width="500px">
      <v-sheet
        elevation="12"
        max-width="600"
        rounded="lg"
        width="100%"
        class="pa-4 text-center mx-auto">
        <v-icon class="animated-icon" color="blue" size="112"
          >mdi-email-check-outline</v-icon
        >
        <h2 class="text-h5 mb-6 my-3">EMAIL VERIFICATION</h2>
        <p class="mb-4 text-medium-emphasis text-body-2">
          Please check your email for the verification link.<br />
          Once verified, you can proceed to login.
        </p>
        <v-divider class="mb-4"></v-divider>
        <div class="text-center">
          <v-btn
            class="text-none my-2"
            color="#07185c"
            rounded
            outlined
            dark
            variant="flat"
            width="200px"
            @click="routeToLogin">
            DONE
          </v-btn>
        </div>
      </v-sheet>
    </v-dialog>

    <!-- Loading Overlay -->
    <v-row justify="center">
      <v-overlay :value="loading" style="z-index: 999">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-row>
  </v-container>
</template>

<script>
  import emailjs from "@emailjs/browser";
  import { auth, db } from "@/firebaseConfig";
  import { signInWithEmailAndPassword } from "firebase/auth";
  import { getDoc, setDoc, doc, updateDoc } from "firebase/firestore";
  import countriesData from "@/assets/countries/countries.json";
  import CellCodeData from "@/assets/countries/CellCode.json";

  export default {
    data: () => ({
      regType: "Individual",
      loading: false,
      TrustName: "",
      IdNum: "",
      isAgreed: false,
      name: "",
      lastName: "",
      email: "",
      rawEmail: "",
      password: "",
      confirmPassword: "",
      dialog: false,
      verifyDialog: false,
      companyName: "",
      PhoneNumber: "",
      PhoneNumberError: "",
      country: "",
      showPassword: false,
      showConfirmPassword: false,
      userState: "Awaiting Documents",
      showForm: false,
      uploadingDocs: false,
      selectedCountryCode: null,
      showSuccessAlert: false,
      showErrorAlert: false,
      errorMessage: "",
      termsAccepted: false,
      selectedCountry: null,
      selectedCountryCode: "",
      countryCodes: [],
      countries: countriesData.map((country) => ({
        text: country.name,
        value: country.code,
        imageUrl: country.image,
      })),
      CellCode: CellCodeData.map((cellCode) => ({
        text: cellCode.dial_code,
        value: cellCode.dial_code,
        imageUrl: cellCode.image,
      })),
      emailRules: [
        (v) => !!v || "Email is required",
        (v) => /.+@.+\..+/.test(v) || "Email must be valid",
      ],
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 6) || "Password must be at least 6 characters",
        (v) => /[A-Z]/.test(v) || "Needs one uppercase letter",
        (v) => /[a-z]/.test(v) || "Needs least one lowercase letter",
        (v) => /[0-9]/.test(v) || "Needs at least one number",
        (v) => /[!@#\$%\^&*]/.test(v) || "Needs at least one special character",
      ],
      nameRules: [
        (v) => !!v || "This field is required",
        (v) => v.length <= 50 || "Name must be less than 50 characters",
      ],
      regRules: [(v) => !!v || "Registration number is required"],
      countryRules: [(v) => !!v || "Country selection is required"],
      phoneRules: [
        (v) => !!v || "Phone number is required",
        (v) =>
          /^[1-9]\d{8,14}$/.test(v) ||
          "Phone number must be valid and cannot start with 0",
      ],
      hasStartedTyping: {
        TrustName: false,
        companyName: false,
        name: false,
        lastName: false,
        formattedEmail: false,
        password: false,
        confirmPassword: false,
        PhoneNumber: false,
      },
    }),
    methods: {
      sanitizeEmailInput() {
        this.email = this.rawEmail.replace(/[\s\t]/g, "").toLowerCase();
      },
      getErrorMessages(field) {
        switch (field) {
          case "TrustName":
            return this.TrustName ? [] : ["Trust name is required"];
          case "companyName":
            return this.companyName ? [] : ["Company name is required"];
          case "name":
            return this.name ? [] : ["Name is required"];
          case "lastName":
            return this.lastName ? [] : ["Last name is required"];
          case "formattedEmail":
            return /.+@.+\..+/.test(this.email) ? [] : ["Email must be valid"];
          case "password":
            return this.passwordRules
              .map((rule) =>
                rule(this.password) === true ? null : rule(this.password)
              )
              .filter((message) => message !== null);
          case "confirmPassword":
            return this.password === this.confirmPassword
              ? []
              : ["Passwords do not match"];
          case "PhoneNumber":
            return this.phoneRules
              .map((rule) =>
                rule(this.PhoneNumber) === true ? null : rule(this.PhoneNumber)
              )
              .filter((message) => message !== null);
          default:
            return [];
        }
      },
      handleInput(event) {
        const input = event.target.value.replace(/[^\d]/g, ""); // Remove any non-digit characters
        this.PhoneNumber = input;
        if (this.PhoneNumber.startsWith("+")) {
          this.PhoneNumber = this.PhoneNumber.substring(1); // Remove "+" if it appears
        }
        if (input.length === 1 && input.startsWith("0")) {
          this.PhoneNumberError = "Phone number cannot start with 0";
        } else {
          this.PhoneNumberError = "";
        }
      },
      async sendVerificationEmail(uid, email) {
      const verificationLink = `https://dashboard.hodlotc.com/verifyaccount?uid=${uid}`;
      const payload = {
        toEmail: email,
        subject: "Verify Your Account",
        link: verificationLink,
      };

      try {
        const response = await fetch(
          "https://hodl-vm.northeurope.cloudapp.azure.com/api/EmailApi/send",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log("Verification email sent successfully:", data);
        this.$toast.success("Verification email sent successfully!"); // Toast notification for success
      } catch (error) {
        console.error("Failed to send verification email:", error);
        this.$toast.error("Failed to send verification email."); // Toast notification for error
      }
    },
      async createUserAuth() {
        this.sanitizeEmailInput();

        let body = JSON.stringify({
          data: {
            email: this.email,
            to_email: this.email,
            password: this.password,
          },
        });

        try {
          this.loading = true;
          let res = await fetch(
            "https://europe-west2-hodl-f3ae3.cloudfunctions.net/createAuthUser",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: body,
            }
          );

          if (res.ok) {
            const responseBody = await res.json();

            if (responseBody.result.success) {
              this.showSuccessAlert = true;
              this.showErrorAlert = false;
              await this.saveUser(this.email, this.password);
              this.verifyDialog = true;
              this.loading = false;
            } else if (responseBody.result.error) {
              this.errorMessage = responseBody.result.error;
              this.showSuccessAlert = false;
              this.showErrorAlert = true;
              this.loading = false;
            }
          } else {
            throw new Error("Failed to create user");
          }
        } catch (error) {
          this.errorMessage = "Something went wrong, please try again!";
          this.showSuccessAlert = false;
          this.showErrorAlert = true;
          this.loading = false;
        }
      },
      async sendToApi(userData) {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const dataToSend = {
          companyName: userData.companyName || "",
          companyVat: "",
          trustName: userData.TrustName || "",
          trustRegNum: "",
          name: userData.name || "",
          lastName: userData.lastName || "",
          middleName: "",
          email: userData.email || "",
          password: "",
          city: "",
          country: userData.selectedCountry || "",
          postalCode: "",
          suburb: "",
          streetNumber: "",
          streetName: "",
          PhoneNumber: userData.PhoneNumber || "",
          emailVerified: "",
          idVerified: "",
          twoFactorAuthLink: "",
          twoFactorAuthStatus: "",
          identityNumber: "",
          idBookFront: "",
          driversFront: "",
          driversBack: "",
          idCardFront: "",
          idCardBack: "",
          passportFront: "",
          honeyCombResult: "",
          errorMessage: "",
        };
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify(dataToSend),
          redirect: "follow",
        };

        try {
          const response = await fetch(
            "https://hodl-vm.northeurope.cloudapp.azure.com/api/RegistrationInsert",
            requestOptions
          );
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        } catch (error) {
          console.error("Error sending data to API:", error);
        }
      },
      sendReferralInfo() {
        const getQueryParam = (param) => {
          const urlParams = new URLSearchParams(window.location.search);
          return urlParams.get(param);
        };

        const uniqueId = getQueryParam("code");

        if (uniqueId) {
          const myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");

          const referralData = JSON.stringify({
            Link: `https://dashboard.hodlotc.com/register?code=${uniqueId}`,
            Email: this.email,
          });

          const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: referralData,
            redirect: "follow",
          };

          fetch(
            "https://hodl-vm.northeurope.cloudapp.azure.com/api/CompletedReferralInsert",
            requestOptions
          )
            .then((response) => response.text())
            .catch((error) =>
              console.error("Error sending referral info:", error)
            );
        }
      },
      async updateDailyCount() {
        const today = new Date().toISOString().split("T")[0];
        const dailyCountDocRef = doc(db, "dailyCounts", today);

        try {
          const docSnap = await getDoc(dailyCountDocRef);
          if (docSnap.exists()) {
            const newCount = docSnap.data().count + 1;
            await updateDoc(dailyCountDocRef, { count: newCount });
            return newCount;
          } else {
            await setDoc(dailyCountDocRef, { count: 1 });
            return 1;
          }
        } catch (error) {
          console.error("Error updating daily count:", error);
          return 1;
        }
      },
      async generateApplicationId(dailyCount) {
        const countryCode = this.selectedCountry;
        const date = new Date().toISOString().split("T")[0].replace(/-/g, "");
        const roleCode = this.getRoleCode();
        return `${countryCode}-${date}-${dailyCount}-${roleCode}`;
      },
      getRoleCode() {
        switch (this.regType) {
          case "Company":
            return "C";
          case "Individual":
            return "I";
          case "Trust":
            return "T";
          default:
            return "";
        }
      },
      async saveUser() {
        if (this.password !== this.confirmPassword) {
          this.errorMessage = "Passwords do not match";
          this.showSuccessAlert = false;
          this.showErrorAlert = true;
          return;
        }

        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().split("T")[0];
        const formattedTimeWithMilliseconds = currentDate
          .toISOString()
          .split("T")[1]
          .split("Z")[0];
        const registrationDateTime = `${formattedDate} ${formattedTimeWithMilliseconds}`;

        let Role = "";
        if (this.regType === "Company") {
          Role = "Company";
        } else if (this.regType === "Individual") {
          Role = "Individual";
        } else if (this.regType === "Trust") {
          Role = "Trust";
        }

        try {
          const dailyCount = await this.updateDailyCount();
          const userCredential = await signInWithEmailAndPassword(
            auth,
            this.email,
            this.password
          );
          const userId = userCredential.user.uid;
          const appId = await this.generateApplicationId(dailyCount);
          await this.sendVerificationEmail(userId, this.email);

          let userData = {
            uid: userId,
            appId: appId,
            email: this.email,
            name: this.name,
            lastName: this.lastName,
            country: this.selectedCountry,
            state: this.userState,
            cellCode: this.selectedCountryCode,
            PhoneNumber: this.PhoneNumber,
            role: Role,
            registrationDate: registrationDateTime,
            isAgreed: this.isAgreed,
          };

          if (this.regType === "Company") {
            userData.companyName = this.companyName;
          }
          if (this.regType === "Trust") {
            userData.TrustName = this.TrustName;
          }

          let collectionName = "";
          switch (this.regType) {
            case "Company":
              collectionName = "companies";
              break;
            case "Individual":
              collectionName = "individuals";
              break;
            case "Trust":
              collectionName = "trusts";
              break;
            default:
              throw new Error("Invalid registration type");
          }

          await setDoc(doc(db, collectionName, userId), userData);

          if (this.regType === "Company" || this.regType === "Trust") {
            const collectionName = this.regType.toLowerCase() + "s" + "Count";
            const docRef = doc(
              db,
              collectionName,
              this.companyName || this.TrustName
            );
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
              const users = docSnap.data().users || [];
              users.push(userId);
              const newCount = docSnap.data().count + 1;
              await updateDoc(docRef, { users: users, count: newCount });
            } else {
              await setDoc(docRef, { users: [userId], count: 1 });
            }
          }

          this.sendToApi(userData);
          this.uploadingDocs = true;
          this.errorMessage = "";
          this.verifyDialog = true;
        } catch (error) {
          console.error("Error in user registration: ", error.message);
          this.errorMessage = "Error in user registration: " + error.message;
          this.showSuccessAlert = false;
          this.showErrorAlert = true;
        }
      },
      sendAdminNotification() {
        const clientEmail = this.rawEmail;

        const templateParams = {
          client_email: clientEmail,
          message: `
          A client has registered. Please verify their account.
          Client Email: ${clientEmail}
        `,
        };
        emailjs
          .send(
            "service_9esjl09",
            "template_u7lqqom",
            templateParams,
            "mr1E1BaGMKZMEzWVu"
          )
          .then(
            (response) => {
              console.log(
                "Admin notification email sent successfully!",
                response.status,
                response.text
              );
            },
            (error) => {
              console.error("Failed to send admin notification email.", error);
            }
          );
      },
      executeRecaptcha() {
        grecaptcha.ready(() => {
          grecaptcha
            .execute("6LeDERMqAAAAAIr-uwYKwM1ai1I25jZtVFwh-JbT", {
              action: "submit",
            })
            .then((token) => {
              console.log("reCAPTCHA token:", token);
            });
        });
      },
      preventLeadingZero(event) {
        if (this.PhoneNumber.length === 0 && event.key === "0") {
          event.preventDefault();
        }
      },
      routeToLogin() {
        this.verifyDialog = false;
        this.$router.push("/");
      },
      async fetchCountryCodes() {
        try {
          const response = await fetch("/assets/CellCode.json");
          const data = await response.json();
          this.countryCodes = data.map((country) => ({
            text: country.name,
            value: country.code,
          }));
        } catch (error) {
          console.error("Error fetching country codes:", error);
        }
      },
      updatePhoneNumber() {
        if (this.selectedCountryCode) {
          // Remove any "+" from the selected country code
          const sanitizedCountryCode = this.selectedCountryCode.replace(
            "+",
            ""
          );
          // Check if the phone number starts with the sanitized country code
          if (!this.PhoneNumber.startsWith(sanitizedCountryCode)) {
            this.PhoneNumber = sanitizedCountryCode + this.PhoneNumber;
          }
        }
      },
    },
    computed: {
      formattedEmail: {
        get() {
          return this.rawEmail;
        },
        set(value) {
          this.rawEmail = value.toLowerCase();
        },
      },
      isPasswordMatch() {
        return (
          this.password === this.confirmPassword && this.password.length > 0
        );
      },
      isFormValid() {
        return (
          this.emailRules.every((rule) => rule(this.email) === true) &&
          this.nameRules.every((rule) => rule(this.name) === true) &&
          this.passwordRules.every((rule) => rule(this.password) === true) &&
          this.passwordRules.every(
            (rule) => rule(this.confirmPassword) === true
          ) &&
          this.password === this.confirmPassword &&
          this.countryRules.every(
            (rule) => rule(this.selectedCountry) === true
          ) &&
          this.phoneRules.every((rule) => rule(this.PhoneNumber) === true) &&
          this.selectedCountryCode &&
          this.isAgreed
        );
      },
      formattedPhoneNumber: {
        get() {
          return this.PhoneNumber;
        },
        set(value) {
          const newValue = value.replace(/[^0-9]+/g, "");
          if (!newValue.startsWith("0") || this.PhoneNumber.length > 0) {
            this.PhoneNumber = newValue;
          }
        },
      },
    },
    watch: {
      regType(newValue) {
        this.showForm = false;
        setTimeout(() => {
          this.showForm = true;
        }, 100);
      },
      TrustName() {
        if (!this.hasStartedTyping.TrustName) {
          this.hasStartedTyping.TrustName = true;
        }
      },
      companyName() {
        if (!this.hasStartedTyping.companyName) {
          this.hasStartedTyping.companyName = true;
        }
      },
      name() {
        if (!this.hasStartedTyping.name) {
          this.hasStartedTyping.name = true;
        }
      },
      lastName() {
        if (!this.hasStartedTyping.lastName) {
          this.hasStartedTyping.lastName = true;
        }
      },
      formattedEmail() {
        if (!this.hasStartedTyping.formattedEmail) {
          this.hasStartedTyping.formattedEmail = true;
        }
      },
      password() {
        if (!this.hasStartedTyping.password) {
          this.hasStartedTyping.password = true;
        }
      },
      confirmPassword() {
        if (!this.hasStartedTyping.confirmPassword) {
          this.hasStartedTyping.confirmPassword = true;
        }
      },
      PhoneNumber() {
        if (!this.hasStartedTyping.PhoneNumber) {
          this.hasStartedTyping.PhoneNumber = true;
        }
      },
      PhoneNumber(newValue, oldValue) {
        console.log(`PhoneNumber changed from ${oldValue} to ${newValue}`);
      },
      uploadingDocs(newValue) {
        this.showForm = false;
        setTimeout(() => {
          this.showForm = true;
        }, 100);
      },
    },
    mounted() {
      this.showForm = true;
    },
  };
</script>

<style>
  /* Remove padding from register text fields */
  .register .v-input__slot {
    padding: 0 !important;
  }

  /* Set the color of v-file-inputs to pink */
  .register .v-text-field .v-label {
    color: #be1b74 !important;
    position: relative !important;
    font-weight: bold !important;
    font-size: 11px;
  }

  .error--text {
    color: red !important;
  }
</style>

<style scoped>
  .animated-icon {
    animation: pulse 2s infinite;
  }
  .flag-icon {
    width: 20px;
    height: auto;
    margin-right: 8px;
    vertical-align: middle;
  }
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  .logo {
    margin-top: 2rem;
    height: 80px;
  }

  .card {
    margin-top: 2rem;
    max-width: 80%;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .textfield {
    width: 350px;
    height: 30px;
    background-color: #f8f8f82b;
    font-size: 14px;
  }

  .background {
    background-image: url("../assets/images/Login.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  h1 {
    font-size: 26px;
  }

  .smallParagraph {
    font-size: 15px;
  }
  .video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    pointer-events: none; /* Prevent any interaction with the video */
    user-select: none; /* Prevent selecting or right-clicking the video */
  }
  /* Animations: */
  .slide-enter-active,
  .slide-leave-active {
    transition: transform 0.6s;
  }
  .v-select__selections {
    justify-content: flex-end;
    padding-right: 20px;
  }

  .v-select__slot {
    text-align: right;
  }

  .flag-icon {
    margin-left: 8px;
    margin-right: 0;
  }

  .v-select .v-input__append-inner {
    margin-left: auto;
  }
  .slide-enter,
  .slide-leave-to {
    transform: translateX(100%);
  }
  .custom-tooltip {
    display: inline-block;
    max-width: 200px; /* Set a max width */
    max-height: 100px;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    text-align: center;
    border-radius: 5px;
    white-space: normal; /* Allow text to wrap */
    word-wrap: break-word; /* Break long words */
  }

  .v-tooltip__content {
    display: inline-block !important; /* Ensure tooltip content respects dynamic sizing */
  }

  .v-tooltip__content .v-tooltip__content {
    max-width: none !important;
  }

  .v-tooltip__content > .custom-tooltip {
    max-width: none !important;
  }

  .v-tooltip__content > div {
    display: inline-block;
    max-width: none;
  }
</style>
