<template>
  <v-row class="identity" justify="center">
    <v-col>
      <v-row justify="center" v-if="!verifying">
        <v-card rounded class="white-card full-width pb-8">
          <h3 class="mx-3">Identity Verification</h3>
          <!-- Existing content omitted for brevity -->

          <!-- Verification page -->
          <v-row justify="start">
            <h2 class="mx-3">Personal Info</h2>
          </v-row>

          <!-- Progress Bar -->
          <div justify="end">Progress Completion</div>
          <v-progress-linear
            :value="progress"
            color="blue"
            height="20"
            class="mb-6"
          >
            <div class="progress-bar-percentage">
              {{ Math.round(progress) }}%
            </div>
          </v-progress-linear>

          <!-- Personal Information Fields -->
          <!-- Existing form fields omitted for brevity -->

          <!-- Save Button -->
          <v-row>
            <v-btn
              @click="openConfirmationDialog"
              class="dark-blue-btn mt-4 mx-3"
              rounded
            >
              Save
            </v-btn>
          </v-row>

          <!-- Confirmation Dialog -->
          <v-dialog v-model="showDialog" width="500px">
            <v-sheet
              elevation="12"
              max-width="600"
              rounded="lg"
              width="100%"
              class="pa-4 text-center mx-auto"
            >
              <v-icon class="animated-icon" color="green" size="112"
                >mdi-check-circle</v-icon
              >
              <h2 class="text-h5 mb-6 my-3">VERIFICATION CONFIRMATION</h2>
              <p class="mb-4 text-medium-emphasis text-body-2">
                Are you sure you would like to proceed with verification?
              </p>
              <v-divider class="mb-4"></v-divider>
              <div class="text-center">
                <v-btn
                  class="text-none my-2"
                  color="#07185c"
                  rounded
                  outlined
                  dark
                  variant="flat"
                  width="200px"
                  @click="handleConfirmation"
                >
                  Yes
                </v-btn>
                <v-btn
                  class="text-none mx-2 my-2"
                  color="#07185c"
                  rounded
                  outlined
                  dark
                  variant="outlined"
                  @click="showDialog = false"
                  width="200px"
                >
                  No
                </v-btn>
              </div>
            </v-sheet>
          </v-dialog>

          <!-- Existing content omitted for brevity -->
        </v-card>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import FeatureCard from "../components/Feature-Card.vue";
import emailjs from "@emailjs/browser";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
  getMetadata,
} from "firebase/storage";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
} from "firebase/firestore";
import { auth } from "../firebaseConfig.js";

export default {
  components: {
    FeatureCard,
  },
  data() {
    return {
      userData: {
        // ... all your userData fields ...
      },
      // Other data properties
      showDialog: false,
      alertMessage: "",
      alertType: "success",
      // ... other data properties ...
    };
  },
  computed: {
    // ... existing computed properties ...
  },
  created() {
    this.fetchAccountData();
    this.splitPhoneNumber();
    this.checkUploadedFiles();
  },
  methods: {
    openConfirmationDialog() {
      this.showDialog = true;
    },
    handleConfirmation() {
      this.saveUserData();
      this.showDialog = false;
    },
    async saveUserData() {
      console.log("saveUserData called");
      const db = getFirestore();
      const userEmail = auth.currentUser ? auth.currentUser.email : null;

      if (!userEmail) {
        console.error("User is not authenticated");
        this.alertType = "error";
        this.alertMessage = "You must be logged in to save your data.";
        return;
      }

      const accountCollections = ["individuals", "companies", "trusts"];

      try {
        let userDoc = null;

        // Fetch the user document
        for (let collectionName of accountCollections) {
          const accountCollectionRef = collection(db, collectionName);
          const q = query(
            accountCollectionRef,
            where("email", "==", userEmail)
          );
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            userDoc = querySnapshot.docs[0];
            this.currentCollection = collectionName;
            console.log(`User document found in collection: ${collectionName}`);
            break;
          }
        }

        if (!userDoc) {
          throw new Error("User document not found");
        }

        // Update the user's phone number
        this.userData.PhoneNumber = `${this.userData.cellCode}${this.userData.cellNumber}`;

        // If OvexEmail does not exist, create a new one
        if (!this.userData.OvexEmail) {
          if (this.userData.name && this.userData.idNumber) {
            const name = this.userData.name.replace(/\s+/g, "");
            const lastName = this.userData.lastName.replace(/\s+/g, "");
            const idNumber = this.userData.idNumber.replace(/\s+/g, "");
            const OvexEmail = `${name}.${lastName}.${idNumber}@hodlotc.com`;
            this.userData.OvexEmail = OvexEmail;
          }
        }

        // Update the document with the new user data
        await updateDoc(
          doc(db, this.currentCollection, userDoc.id),
          this.userData
        );

        console.log("User data updated successfully in Firestore");

        // Send data to the API
        await this.sendToApi(this.userData);

        // Send user data email
        await this.sendUserDataEmail(
          this.userData.email,
          this.userData.lastName,
          this.userData.name,
          this.userData.idNumber,
          this.userData.OvexEmail
        );

        // Provide feedback to the user
        this.alertType = "success";
        this.alertMessage = "Your data has been saved successfully!";
      } catch (error) {
        console.error("Error updating document: ", error);
        this.alertType = "error";
        this.alertMessage = `Error saving data: ${error.message}`;
      }
    },
    async sendUserDataEmail(email, lastName, name, idNumber, OvexEmail) {
      try {
        const templateParams = {
          to_email: "hodlwebsite@gmail.com",
          subject: "Registration Added",
          message: `New User has been added with these credentials:
          \nEmail: ${email}
          \nName: ${name}
          \nIDNumber: ${idNumber}
          \nOvexEmail: ${OvexEmail}
          Please create their Ovex Account.`,
        };

        await emailjs.send(
          "service_9esjl09", // Replace with your Email.js service ID
          "template_ta9fu4j", // Replace with your Email.js template ID
          templateParams,
          "mr1E1BaGMKZMEzWVu" // Replace with your Email.js user ID
        );
      } catch (error) {
        console.error("Error sending email", error);
      }
    },
    async sendToApi(userData) {
      const myHeaders = new Headers();
      myHeaders.append("APIKey", "t3iKRnspNMAyNmEoJg6t");
      myHeaders.append("Content-Type", "application/json");

      let payload = {
        // Map your userData fields to the API payload
        // For example:
        companyName: userData.companyName || "",
        companyVat: userData.companyVAT || "",
        name: userData.name || "",
        lastName: userData.lastName || "",
        // ... other fields ...
        honeyCombResult: "",
        errorMessage: "",
      };

      const raw = JSON.stringify(payload);

      try {
        const response = await fetch(
          "https://hodl-vm.northeurope.cloudapp.azure.com/api/RegistrationUpdate",
          {
            method: "PUT",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          }
        );
        const result = await response.text();
        console.log("Data sent to API successfully:", result);
      } catch (error) {
        console.error("Error sending data to API:", error);
      }
    },
    // Existing methods...
    openFileDialog(refName) {
      this.$refs[refName].click();
    },
    async handleFileChange(event) {
      const file = event.target.files[0];
      if (!file) return;

      const docType = event.target.getAttribute("data-type");
      if (!docType) {
        console.error("Document type not found");
        return;
      }

      try {
        const fileUrl = await this.uploadFileToStorage(docType, file);
        if (fileUrl) {
          this.$set(this.userData, docType, fileUrl);

          // Update the corresponding upload status flag
          switch (docType) {
            case "idCardFrontUrl":
              this.userData.idCardFrontUploaded = true;
              break;
            case "idCardBackUrl":
              this.userData.idCardBackUploaded = true;
              break;
            case "proofOfResidenceUrl":
              this.userData.proofOfResidenceUploaded = true;
              break;
            case "bankAccountProofUrl":
              this.userData.bankAccountProofUploaded = true;
              break;
            case "selfieUrl":
              this.userData.selfieUploaded = true;
              break;
          }
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    },
    async uploadFileToStorage(docType, file) {
      const storage = getStorage();
      const userEmail = auth.currentUser.email;
      const fileRef = storageRef(storage, `documents/${userEmail}/${docType}`);
      await uploadBytes(fileRef, file);
      return await getDownloadURL(fileRef);
    },
    async fetchAccountData() {
      const db = getFirestore();
      const userEmail = auth.currentUser ? auth.currentUser.email : null;
      if (!userEmail) {
        console.error("User is not authenticated");
        return;
      }

      let accountCollections = ["individuals", "companies", "trusts"];
      let accountDataFound = false;

      for (let collectionName of accountCollections) {
        if (accountDataFound) break;
        let accountCollectionRef = collection(db, collectionName);
        let q = query(accountCollectionRef, where("email", "==", userEmail));
        let querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          this.userData = querySnapshot.docs[0].data();
          this.currentCollection = collectionName;
          accountDataFound = true;
          console.log(`Account data fetched from collection: ${collectionName}`);
        }
      }

      if (!accountDataFound) {
        console.error("No account data found for the user.");
      }
    },
    splitPhoneNumber() {
      const PhoneNumber = this.userData.PhoneNumber;
      const cellCode = this.userData.cellCode;

      if (PhoneNumber && cellCode && PhoneNumber.startsWith(cellCode)) {
        this.userData.cellNumber = PhoneNumber.slice(cellCode.length);
      } else {
        this.userData.cellNumber = PhoneNumber || "";
      }
    },
    async checkUploadedFiles() {
      const storage = getStorage();
      const userEmail = auth.currentUser ? auth.currentUser.email : null;
      if (!userEmail) {
        console.error("User is not authenticated");
        return;
      }

      const filesToCheck = [
        "idCardFrontUrl",
        "idCardBackUrl",
        "proofOfResidenceUrl",
        "bankAccountProofUrl",
        "selfieUrl",
      ];

      for (let fileType of filesToCheck) {
        const fileRef = storageRef(
          storage,
          `documents/${userEmail}/${fileType}`
        );
        try {
          await getMetadata(fileRef);
          this.userData[fileType] = await getDownloadURL(fileRef);
          // Set the corresponding uploaded status flag to true
          this.userData[`${fileType.replace("Url", "Uploaded")}`] = true;
        } catch (error) {
          // File does not exist
          this.userData[fileType] = null;
          this.userData[`${fileType.replace("Url", "Uploaded")}`] = false;
        }
      }
    },
    // Other methods...
  },
  watch: {
    // Watchers for file upload status
    "userData.idCardFrontUrl"(newVal) {
      this.userData.idCardFrontUploaded = !!newVal;
    },
    "userData.idCardBackUrl"(newVal) {
      this.userData.idCardBackUploaded = !!newVal;
    },
    "userData.proofOfResidenceUrl"(newVal) {
      this.userData.proofOfResidenceUploaded = !!newVal;
    },
    "userData.bankAccountProofUrl"(newVal) {
      this.userData.bankAccountProofUploaded = !!newVal;
    },
    "userData.selfieUrl"(newVal) {
      this.userData.selfieUploaded = !!newVal;
    },
  },
};
</script>

<style>
  .identity .v-file-input .v-label {
    color: #be1b74;
    font-weight: bold;
  }

  .qr .v-label {
    max-width: unset !important;
    left: auto !important;
  }
  .flag-icon {
    width: 20px;
    height: 15px;
    object-fit: contain;
  }
  .green-text {
    color: #4caf50 !important;
  }

  .red-text {
    color: #f44336 !important;
  }

  .pink-btn-border {
    border: 1px solid #e91e63;
    color: #e91e63;
  }

  .pink-btn-border:hover {
    background-color: #e91e63;
    color: white;
  }

  .flag-icon {
    width: 20px;
    height: 15px;
    object-fit: contain;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
  }
</style>
